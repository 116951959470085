/* FAQ COLLAPSE/EXPAND STYLES */
.view-faqs {
	margin-bottom: 20px;
	
	.views-row {
		margin-bottom: 20px;
		&:last-child {
			margin-bottom:0;
		}
	}

	.card-qa-answer {
		display: none;
		padding: 10px 10px 10px 50px;	
		box-sizing: border-box;
		font-size: 1em;
 
		:last-child {
			margin-bottom: 0;
		}
	}
	
	.card-qa-question {
		text-decoration: none;
		color: $lapis_lazuli;
		font: 300 1.111em / 1 $title-font-family;
		padding: 10px 0 10px 50px;
		line-height: 20px;
		display: block;
		/*border: 1px solid #d0d0d0;*/
		margin-bottom: -1px;
		cursor: pointer;
		box-sizing: border-box;
		background: $ico-expand-lapis no-repeat scroll 0 50% / 24px 24px transparent;
		&:hover {
			color:$maximum_blue;
			background: $ico-expand-maximum no-repeat scroll 0 50% / 24px 24px transparent;
		}
		&.active {
			background: $ico-collapse-lapis no-repeat scroll 0 50% / 24px 24px transparent;
			&:hover {
				color:$maximum_blue;
				background: $ico-collapse-maximum no-repeat scroll 0 50% / 24px 24px transparent;
			}
		}
	}
}

// PALETTE SPECIFIC

.section-row {
	&.palette-highlight {
		.view-faqs {
			.card-qa-answer {
				color:white;
			}
			.card-qa-question {
				color: $maximum_blue;
				background: $ico-expand-maximum no-repeat scroll 0 50% / 24px 24px transparent;
				&:hover {
					color:$blue_sapphire;
					background: $ico-expand-sapphire no-repeat scroll 0 50% / 24px 24px transparent;
				}
				&.active {
					background: $ico-collapse-maximum no-repeat scroll 0 50% / 24px 24px transparent;
					&:hover {
						background: $ico-collapse-sapphire no-repeat scroll 0 50% / 24px 24px transparent;
					}
				}
			}
		}
	}
}