// /* MAIN MENU */ //

$nav-base-font        	 	: 1.222rem/1 $title-font-family;
$nav-base-font-smaller 		: 1rem/1.375 $title-font-family;
$nav-sub-base-font        : 1rem/1 $base-font-family;

$nav-wrapper-bg-color					: linear-gradient(90deg, #056A85 0%, #063748 100%);
$nav-base-bg-color        		: transparent; 
$nav-base-text-color     		 	: white;
$nav-base-padding        			: 0.556rem 1.111rem;
$nav-base-hover-padding   		: 0.556rem 1.111rem;
$nav-sub-base-padding					: 0.667rem 2.778rem 0.667rem 1.111rem;
$nav-sub-base-hover-padding		: 0.667rem 2.778rem 0.667rem 1.111rem;

$nav-base-padding-smaller 				: 0.375rem 1.125rem; //for use at awkward breakpoints so the menu still fits on one line
$nav-base-hover-padding-smaller 	: 0.375rem 1.125rem;

$nav-active-bg-color      		: transparent;
$nav-active-text-color    		: $maximum_blue;
$nav-active-border-radius 		: 0; 

$nav-hover-bg-color       		: rgba(255, 255, 255, 0.3);
$nav-hover-text-color     		: white;
$nav-hover-border	  	    		: none;

$nav-highlight-bg-color   		: rgba(255, 255, 255, 0.3);
$nav-highlight-text-color 		: white;
$nav-highlight-border	    		: none;

$nav-sub-bg-color         		: white;
$nav-sub-hover-bg-color   		: $glitter;
$nav-sub-text-color       		: $primary;
$nav-sub-hover-text-color   	: $primary;
$nav-sub-box-shadow      			: none;
$nav-sub-border          			: none;
$nav-sub-border-radius  		  : none;

$nav-mob-text-color      						: $white;
$nav-mob-active-text-color      		: $maximum_blue;
$nav-mob-active-bg-color      			: $azureish_white;

$nav-mob-1st-bg-color     					: $warm_black;
$nav-mob-1st-highlight-bg-color     : $warm_black;
$nav-mob-1st-highlight-text-color   : $maximum_blue;
$nav-mob-1st-line-color     				: linear-gradient(90deg, #056A85 0%, #063748 100%);
$nav-mob-1st-border-color     			: linear-gradient(90deg, #056A85 0%, #063748 100%);
$nav-mob-1st-padding          			: 0.889rem 3.333rem 0.889rem 1.667rem;

$nav-mob-2nd-bg-color     					: white;
$nav-mob-2nd-highlight-bg-color     : $azureish_white;
$nav-mob-2nd-highlight-text-color   : $maximum_blue;
$nav-mob-2nd-line-color     				: none;
$nav-mob-2nd-border-color     			: none;

$nav-mob-3rd-bg-color     					: $azureish_white;
$nav-mob-3rd-highlight-bg-color     : $glitter;
$nav-mob-3rd-highlight-text-color   : $primary;
$nav-mob-3rd-line-color     				: none;
$nav-mob-3rd-border-color     			: none;

$nav-mob-4th-bg-color     					: $azureish_white;
$nav-mob-4th-highlight-bg-color     : $glitter;
$nav-mob-4th-highlight-text-color   : $primary;
$nav-mob-4th-line-color     				: none;
$nav-mob-4th-border-color     			: none;

$nav-mob-border											: 1px solid $slate_gray;
$nav-mob-expanded-border						: 1px solid $slate_gray;
$nav-mob-border-hover								: none;
$nav-mob-shadow											: none;



/* MOBILE MENU TRIGGER */
$nav-button-bg-color						: $warm_black;
$nav-button-hover-bg-color			: $warm_black;
$nav-button-height      				: 78px;
$nav-button-width      					: 86px;
$nav-button-bar-bg-color      	: white;
$nav-button-bar-hover-bg-color  : white;
$nav-button-bar-height      		: 6px;
$nav-button-bar-width      			: 46px;
$nav-button-bar-border-radius 	: 10px;
