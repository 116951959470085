// /* Add all of your colours to var-colors and apply them to different remora elements in var-config.
// Unless a style needs to be backported to remora, you do not need to create anything in var-config.
// Instead, override it in custom client scss files. */


// /* TEXT */ //
$primary           : #454851; /* outer_space */ 
$secondary         : #2A2D34; /* gunmetal */ 

// /* MAIN PALETTE */ //
$blue_sapphire     : #0C6786; /* blue_sapphire */
$lapis_lazuli      : #1F74A1; /* lapis_lazuli */
$maximum_blue      : #3CA3D9; /* maximum_blue */

// /* SEC PALETTE */ //
$glitter           : #E7F3FA; /* glitter */
$ghost_white       : #F8F8F9; /* ghost_white */
$glitter-alt       : #E6EBED; /* highlight palette card hover */
$antiflash_white   : #F2F2F2; /* antiflash_white */
$azureish_white    : #CAE6F5; /* azureish_white */
$slate_gray        : #747988; /* slate_gray */
$cornflour_blue    : #91CBEA; /* cornflour_blue */
$floral_white      : #FFFCF2;
$pale_aqua         : #BADDF0;
$columbia_blue     : #C0DFEF;
$outer-space       : #454851;
$warm_black        : #063748;
$link_blue         : #007BBD;

$black             : #000;
$grey              : #666;
$white             : #FFF;

// BG COLOURS FOR EVENT-DATE-WRAPPER

$date_bg_alt_3           : #E5F0F6;
$date_bg_alt_2           : #D6EBF7;
$date_bg_alt_2_hover     : #B4DBF0;
$date_bg_alt_1           : #EBF3EF;
$date_bg_alt_1_hover     : #C5E1EB;
$date_bg_highlight       : #CDD7DA;
$date_bg_highlight_hover : #9BAFB6;
$date_bg_default         : #E5F0F6;
$date_bg_default_hover   : $columbia_blue;

// FILES
$dark_spring_green 		: #207347; /* xlsx */
$bdazzled_blue 				: #2D5994; /* docx */
$lemon_curry 					: #D19B15; /* jpg */
$internationl_orange 	: #C11E07; /* pdf */
$dark_pastel_red 			: #D04727; /* pptx */
$dark_imperial_blue 	: #023A6D; /* txt */

$light_grey        		: #CDDDD5; /* xlsx */
$columbia_blue 				: #CFD8E5; /* docx */
$almond      					: #F0E5CB; /* jpg */
$dust_storm          	: #EDCCC9; /* pdf */
$champagne       			: #F0D5CF; /* pptx */
$columbia            	: #C7D2DD; /* txt */
$lavender_grey 				: #C6C6C7; /* zip */

$file_ext_highlight   : #CDD7DA; /* file extension bg for highlight palette */
$file_colour_highlight: #0C6786; /* color for highlight palette */
$file_bg_highlight    : #E6EBED; /* file-name bg for highlight palette */
$file_bg_alt_2        : #91CBEA; /* file-name bg for alt-2 palette */
$file_bg_alt_2_hover  : #BADDF0; /* file-name bg hover for alt-2 palette */

$xlsx_color	 					: $dark_spring_green; /* xlsx */
$docx_color	 					: $bdazzled_blue; /* docx */
$jpg_color	 					: $lemon_curry; /* jpg */
$pdf_color	 					: $internationl_orange; /* pdf */
$pptx_color	 					: $dark_pastel_red; /* pptx */
$txt_color	 					: $dark_imperial_blue; /* txt */
$zip_color	 					: black; /* zip */

$xlsx_bg	  					: $light_grey; /* xlsx */
$docx_bg  	 					: $columbia_blue ; /* docx */
$jpg_bg   	 					: $almond; /* jpg */
$pdf_bg   	 					: $dust_storm; /* pdf */
$pptx_bg  	 					: $champagne; /* pptx */
$txt_bg     					: $columbia; /* txt */
$zip_bg               : $lavender_grey; /* zip */


// /* LINKS */ //
$action-default      : $link_blue;
$action-hover        : $maximum_blue;
$action-active       : $maximum_blue; 
$action-active-hover : $maximum_blue; 


// /* SHADOWS */ //
$box-shadow : 0px 2px 8px rgba(0, 0, 0, 0.1);


// /* BORDERS */ //
$primary-border-color          : rgba(44, 63, 78,0.1);
$primary-border-radius         : 0;
$primary-border                : 1px solid $primary-border-color;

$secondary-border-color        : rgba(44, 63, 78,0.1);
$secondary-border-color-radius : 0;
$secondary-border              : 1px solid $secondary-border-color;

$tertiary-border-color         : #F3F3F3;
$tertiary-border-radius        : 0;
$tertiary-border               : 1px solid $tertiary-border-color;

$base-border                   : 1px solid rgba(44, 63, 78,0.1);
$base-border-radius            : 0;

// /* FORMS  */ //
$fieldset-border-color : $secondary;
$form-box-shadow       : $box-shadow;
$form-box-shadow-focus : $box-shadow; 
$form-border-color     : $secondary-border-color;
$form-border           : 1px solid $secondary-border-color;
$form-border-radius    : 0;

$button-bg             : $glitter;
$button-hover-bg       : $azureish_white;
$button-text           : $action-hover;
$button-border         : none;
$button-border-radius  : 0;
$button-hover-text     : $action-default;



// /* MESSAGES */ //
$alert-color           : lighten(#f7db55, 33%); // /* CLASS: warning */
$error-color           : lighten(#fa4e7b, 33%); // /* CLASS: error */
$notice-color          : lighten(#5d86c5, 38%); // /* CLASS: notice */
$success-color         : lighten(#79c68a, 35%); // /* CLASS: status */
$message-border-radius : 3px;
$message-border-style  : 1px dotted;


// /* STYLES FROMS NEATO */ //
$base-background-color : white;
$base-font-color       : $primary;
$primary-border-radius : 0;
$flex-box-background   : $glitter !default;