// GENERAL / RESET STYLES

//.front .page > main { display:none; }

.section-row.section-with-slider { padding:0; }

.section-row.section-with-slider .section-inner {
	margin: 0 auto;
	max-width:$site-max-width;
	padding:0;
	position: relative;
}

.slick-slide { margin-left:10px; margin-right:10px; }

// /* COMMON CONTROL/NAV STYLES */
.slick-controls, .slick-nav {
	margin: 0 20px;
	@include media($narrow) {
		margin: 0;
	}
	button.slick-arrow {
		bottom:17px;
		z-index:1;
		text-indent:-999em;
		font-family:$base-font-family;
		overflow:hidden;
		padding: 0;
		width: 46px;
		height: 46px;
		&:before {
			content:"";
			background: $slick-button-bg;
			display: block;
			width: 46px;
			height: 46px;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 1;
		}
		&.slick-prev {
			left:22px;
			background-size:contain;
			@include media($narrow) {
				left:2px;
			}
		}
		&.slick-next {
			right:22px;
			background-size:contain;
			@include media($narrow) {
				right:2px;
			}
		}
	}
	.slick-dots {
		padding:0 20px;
		li {
			width:14px;
			height:14px;
			margin:5px;
			button {
				display:inline-block;
				width:6px;
				height:6px;
				border-radius:50%;
				&:before {
					content:"";
					display:none;
				}
			}
		}
	}	
}

// /* SLICK CONTROLS ONLY -- everywhere but slides view which uses .slick-nav class instead
.slick-controls {
	height:56px;
	position:relative;
	
	@include media($narrow) {
		display:inline-block;
	}

	button.slick-arrow {
		bottom:17px;
		padding: 15px;
	}
	
	.slick-dots {
		bottom:17px;
	}	
}

.section-row {
	.slick-controls {
		@include media($narrow) {
			width:50%;
		}
		@include media($normal) {
			width:33.333%;
		}
	}
}
.one-sidebar {
	.content-main,
	.content-additional {
		.section-row {
			.slick-controls {
				@include media($narrow) {
					width:100%;
				}
				@include media($normal) {
					width:50%;
				}
			}
		}
	}
}

.side-row {
	.slick-controls {
		width: 100%;
		
		.slick-dots {
			@include media($narrow) {
				display: none !important;
			}
			@include media($wide) {
				display: block !important;
			}
		}
	}
	
	.view-twitter .slick-controls .slick-dots {
		@include media($narrow) {
			display: none !important;
		}
		@include media(1450px) {
			display: block !important;
		}
	}
}


// /* SLICK NAV ONLY
.slick-nav {
	height: 50px;
	&:empty { display: none; }
	button.slick-arrow {
		// padding: 25px;
	}
	.slick-dots {
		bottom:14px;
	}	
}


.front .section-row.palette-alt-1.section-with-slider .section-inner .view-slider .view-content .slick-list .slick-track .views-row .card-slide-description, 
.not-front .content-hero .section-row.palette-alt-1.section-with-slider .section-inner .view-slider .view-content .slick-list .slick-track .views-row .card-slide-description {
	background:white;
	color:$primary;
}



// SLIDER VIEWS
.view-slider {
	position:relative;
	.view-content {
		margin:0;
		.slick-list {
			overflow:visible;
			.slick-track {
				background-color:$slide-text-bg-color;
				
				@include media($narrow) {
					background-color: transparent;
				}
				
				.views-row {
					margin: 0;

					.card-slide-image {
						position:relative;
						img {
							max-width:none;
							position:relative;
							width: 100%;

							@include media($narrow) {
								left: -10px;
								width: calc(100% + 20px);
							}

							@include media($wide) {
								left:calc(50% - 800px);
								width: auto;
							}
						}
					}
					
					.card-slide-description {
						text-align: center;
						bottom:0;
						position:relative;
						background-color:$slide-text-bg-color;
						font:$slide-text-font;
						padding:17px 20px 85px 20px;
						margin:0;
						color:$slide-text-color;
						min-height:66px;
						border-radius: $slide-text-border-radius;
						
						p {
							font:$slide-text-font;
							margin: 0;
						}

						@include media($narrow) {
							position:absolute;
							width:330px;
							padding:30px 20px 20px;
							bottom: 60px;
							left: calc(50% - 165px);
						}
						@include media($normal) {
							width:390px;
							left: auto;
					    right: calc(10% + 17px);
						}
						@include media($wide) {
							top: auto;
							right: calc(120px + 15px);
							bottom: calc(100% - 500px);
						}
						@media (min-width:1600px) {
							top: auto;
							right: calc(50% - 750px + 125px);
							bottom: calc(100% - 450px);
						}
					}
					&.views-row-first.views-row-last {
						.card-slide-description {
							@include media($narrow) {
								padding: 30px 20px;
								border-radius: 6px;
							}
						}
					}
				}
			}
		}
		
		.card-edit-node a {
			@include button-states($button-bg, $button-text, $button-border-color);
			font-size: 0.875rem;
			font-weight: bold;
			padding: 5px 15px;
			position: absolute;
			left: 0;
			text-transform: uppercase;
			top: 0;
		}
	}
	
	// SLIDER NAVIGATION
	.view-footer {
		position:absolute;
		bottom:10px;
		width:100%;
		left:0;
		
		@include media($narrow) {
			width:370px;
			left: calc(50% - 185px);
			bottom: 10px;
		}
		@include media($normal) {
			width:440px;
			left: auto;
			right: 10%;
		}
		@include media($wide) {
			top: auto;
			right: 120px;
			bottom: calc(100% - 550px);
		}
		@media (min-width:1600px) {
			top: auto;
			right: calc(50% - 750px + 125px);
			bottom: calc(100% - 500px);
		}
	}
}

.section-row .view-slider .view-content .slick-list .slick-track .views-row {
	@include media($narrow) {
		margin-left: 10px;
		margin-right: 10px;
	}
}


// FULL WIDTH SLIDERS
// ONLY AT HOME PAGE ALL REGIONS AND OTHER PAGES ON THE HERO

.front,
.not-front .content-hero {
	.section-row.section-with-slider .section-inner {
		margin: 0 auto;
		max-width:100%; //$site-max-width;
		padding:0;
		position: relative;

		@include media($wide) {
			max-height: 633px;
		}
		@media (min-width:1600px) {
			overflow: hidden;

			.view-slider .view-content .slick-list .slick-track .views-row {
				margin: 0;

				.card-image {
					.card-slide-image {
						position:relative;
						img {
							max-width:none;
							position:relative;
							width: 100%;

							@include media($narrow) {
								left: -10px;
								width: calc(100% + 20px);
							}

							@include media($wide) {
								left:calc(50% - 800px);
								width: auto;
							}							

							@media (min-width:1600px) {
								left:0;
								width: 100%;
							}
						}
					}
				}
				
				.card-slide-description {
					bottom:0;
					position:relative;
					background-color:$slide-text-bg-color;
					font:$slide-text-font;
					// padding:17px 20px 55px 20px;
					margin:0;
					color:$slide-text-color;
					min-height:66px;

					@include media($narrow) {
						background-color:$slide-text-bg-color;
						position:absolute;
						// padding:17px 20px;
						max-width:75%;
					}
					@include media($wide) {
						top: auto;
						right: calc(50% - 650px + 150px);
						bottom: calc(100% - 600px);
					}
					@include media(1600px) {
						top: auto;
						right: calc(50% - 750px + 150px);
						bottom: calc(100% - 450px);
					}
				}
			}
		}

		.view-slider .slick-slider:not(.slick-dotted) {
			.card-description-inner.card-description-empty {
				display: none;
			}
		}

		// .view-slider .view-footer {
		// 	@include media(1600px) {
		// 		top: auto;
		// 		right: calc(50% - 750px + 150px);
		// 		bottom: calc(100% - 500px);
		// 	}
		// }
	}
}


// SLIDERS HINDERED BY SIDEBARS - image
.one-sidebar,
.sidebar-second {
	.content-main,
	.content-additional,
	.sidebar {
		.view-slider {
			.view-content .slick-list .slick-track .views-row {
				.card-slide-image img {
					left: auto;
					width: 100%;
					max-width: 100%; 
				}
				.card-slide-description {
					bottom:0;
					position: relative;
					width:100%;
				}
			}
			.view-footer {
				bottom: 0;
				position:relative;
				width:100%;
				left: auto;
				
			}
		}
	}
}


// PHOTO GALLERY
.card-photos {
	position:relative;
	.photoswipe-gallery {
		margin:0;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
	}
	
	// GALLERY CONTROLS
	.view-footer {
		text-align:center;
		margin-top:20px;
	}
}


// SLIDER / TWITTER
.view-type-slider, .view-twitter {
	position:relative;
	.view-content {
		margin:0;
		position: relative;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
		
	}
	.view-footer {
		text-align:center;
	}
}

// SIDEBAR SLIDESHOWS
aside#sidebar-second {
	.section-with-slider {
		.card-slide-description {
			right: auto;
			border-radius: 0;
		}
		.view-footer {
			right: auto;
			background: $warm_black;
			height: 70px;
			.slick-nav {
				margin: 0 20px;
				position: relative;
			}
		}
	}
}

