// /* TABLES */ //
$table-bg-color           : white;
$base-accent-color        : #477DCA;
$table-border-color       : $glitter;
$table-cell-border        : 1px solid $glitter-alt;
$table-border             : 1px solid $table-border-color;
$table-header-bg-color    : $glitter;
$table-header-bg-color-alt: $cornflour_blue;
$table-header-border      : 1px solid $blue_sapphire;
$table-hover-bg-color     : lighten($glitter, 10%);
$table-stripe-bg          : darken($table-bg-color, 4);
$table-stripe-bg-hover    : darken($table-stripe-bg, 5);
$table-padding            : .75em 1em;
$table-caption-bg-color   : $grey;
$table-caption-text-color : white;
$table-caption-border     : 1px solid white;
$table-caption-font       : 1.125em/1.33333333333333 $title-font-family;

table {
	border-collapse: separate;
	border-spacing: 0;
	margin: 0 0 $vert-space;
	width: auto;
	display: block;
	
	caption {
		background:$table-caption-bg-color;
		border-bottom:$table-caption-border;
		color::$table-caption-text-color;
		font: $table-caption-font;
		padding:$vert-space/3 $vert-space/2;
	}

	th {
		background:$table-header-bg-color;
		border-left: $table-header-border;
		font: 300 1.222em/1 $title-font-family;
		padding: $vert-space/2 0;
		text-align: left;
		word-wrap: break-word;
		&:first-child {
			border-left:none;
		}
	}

	td {
		//border-bottom: $primary-border;
		line-height: $base-line-height;
		padding: $table-padding;
		border-left:$table-cell-border;
		border-right:$table-cell-border;
		word-wrap: break-word;
	}

	thead {
		th {
			background-color: $table-header-bg-color;
			border-bottom: 0px;
			padding: $table-padding;
		}
	}
	
	tr,
	td,
	th {
		vertical-align: middle;
	}

	tbody {
		background-color: $table-bg-color;

		tr:hover > td, tr:hover > th {
			background-color: $table-hover-bg-color;
		}
		tr:first-child td {
			border-top: $table-header-border;
		}
		tr:last-child td {
			border-bottom: $table-border;
		}
		td {
			border-bottom: none;
			border-right:none;
			border-left: $table-header-border;
			border-top: $table-border;
			padding: $table-padding;
			&:first-child {
				border-left: none;
			}

			button {
				display: inline-block;
				font-size: .7em;
				line-height: $base-line-height;
				margin-bottom: .3em;
				margin-right: 0.5em;
				outline: none;
				padding: .3em 1em;
				width: 100%;

				@include media($narrow) {
					margin-bottom: 0;
					width: auto;
				}
			}
		}
	}
}

.section-row {
	&.palette-alt-2,
	&.palette-alt-3 {
		thead {
			th {
				background-color: $table-header-bg-color-alt;
			}
		}
	}
}
