.view-attachments {
	
	// /* LAYOUT */
	ul {
		padding:0;
		margin:0;
	}
	
	.views-row {
		padding: $vert-space/2 0;
	}
	
	.views-row-last {
		.attachment-link {
			margin:0;
		}
	}
	a.attachment-link {
		color:$secondary!important;
		font-family:$title-font-family;
		&:hover {
			color:$action-hover;
			.file-name {
				border:solid 2px $action-hover;
			}
		}
	}
	.attachment-link {
		display:block;
		overflow: visible;
		margin-bottom:$vert-space;
		
		.file-icon {
			display:inline-block;
			margin:0 $horz-space 0 0;
			min-width:4rem;
			.file-extension {
				padding:8px 12px;
				text-transform:lowercase;
				text-align:center;
				font:bold 1.111em / 1 $title-font-family;
				border-radius: 5px 5px 0 0;
				float: left;
			}
			.file-size {
        padding: 10px 10px 3px;
				font-size:0.777em;			
				text-align:center;
				color:$secondary;
				display:inline-block;
				vertical-align:sub;
			}
		}
		
		.file-name {
			overflow:hidden;
			background: white;
			padding:18px;
			font-size: 1.111em;
			border: 2px solid white;
			border-radius: 0px 4px 4px 4px;
			box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
		}
	}


	// /* FILE TYPE COLOURS */
  
  .file-type-docx, .file-type-doc {
    .file-extension {
      background:$docx_bg;
      color:$docx_color;
    }
  }
  .file-type-xlsx, .file-type-xls {
    .file-extension {
      background:$xlsx_bg;
      color:$xlsx_color;
    }
  }
  .file-type-pptx, .file-type-ppt {
    .file-extension {
      background:$pptx_bg;
      color:$pptx_color;
    }
  }
  .file-type-pdf {
    .file-extension {
      background:$pdf_bg;
      color:$pdf_color;
    }
  }
  .file-type-rtf, .file-type-txt {
    .file-extension {
      background:$txt_bg;
      color:$txt_color;
    }
  }
  .file-type-jpg, .file-type-png, .file-type-bmp, gif {
    .file-extension {
      background:$jpg_bg;
      color:$jpg_color;
    }
  }
  .file-type-zip, .file-type-rar, .file-type-7z {
    .file-extension { 
      background:$zip_bg;
      color:$zip_color;
    }
  }
} 

// PALETTE SPECIFIC STYLES

.palette-highlight {
	.view-attachments .attachment-link {
		.file-name {
			
		}
		.file-icon .file-extension  {
			background:$file_ext_highlight;
		}
		&:hover .file-name {
			background: $file_bg_highlight;
			color:$file_colour_highlight;
			border: 2px solid $file_bg_highlight;
		}
	}
}

.palette-alt-3 {
	.view-attachments a.attachment-link {
		.file-name {
			background:$file_bg_alt_2;
			border:2px solid $file_bg_alt_2;
		}
		&:hover .file-name {
			background:$file_bg_alt_2_hover;
			color:$file_colour_highlight;
			border:2px solid $file_bg_alt_2_hover;
		}
	}
}

// RESPONSIVE STYLING

.view-attachments {
	.views-row {
		width:100%;
		float:left;

		@media (min-width:720px) {
			width:47%;
			margin-right:15px;
		}
		
		&:nth-child(2n+1){
			@media (min-width:720px) and (max-width:1199px) {
				clear:left;
			}
		}

		@media (min-width:1200px) {
			width:23%;
			margin-right:15px;
		}
		&:nth-child(4n+1){
		 	@media (min-width:1200px) {
				clear:left;
			}
		}
	}
}

.side-row .view-attachments .views-row {
	width:100%;
}

.one-sidebar,
.two-sidebars {
	.content-main,
	.content-additional {
		.view-attachments	.views-row {
			width:100%;
			@media (min-width:960px) {
				width:48%;
				margin-right:15px;
				&:nth-child(2n+1) {
					clear:left;
				}
				&:nth-child(2n) {
					margin-right:0;
				}
			}
		}
	}
} 
