html, body {
	color:$primary;
	font-family:$base-font-family;
	font-size:$base-font-size;
	line-height:$base-line-height;
	-webkit-font-smoothing: antialiased;
}


h1, h2, h3, h4, h5, h6 {
	color:$secondary;
	font-family:$title-font-family;
	margin-top:0;
	text-transform:none;
	
	&.card-title {
		font-family:$title-font-family;
	}
	&.card-title-sm {
		font-family:$title-font-family;
		font-size:1em;
		margin-bottom:$vert-space/4;
		
		a {
			color:$action-default;
			
			&:hover {
				color:$action-hover;
			}
		}
	}
}
h1, h2 {
	font-weight:300;
}
h3, h4, h5, h6 {
	font-weight:300;
}

.l-region--sidebar-second {
	h3, h4, h5, h6 {
		font-family:$title-font-family;
	}
}
h1 {
	font-size:2.222rem;
	line-height: 1.2045;
	margin-bottom:$vert-space;
	
	@include media($normal) {
		font-size:2.444rem;
	}
}
h2 {
	font-size:1.889rem;
	line-height:1.944;
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:2rem;
	}
}
h3 { 
	font-size:1.556rem;
	line-height:1.2 ;
	margin-bottom:0.5rem;
	
	@include media($normal) {
		font-size:1.667rem;
	}
}
h3.block__title {
	
	font-size:1.556rem;
	
	@media screen and (min-width: 768px) {
		text-align: center;
		font-size:1.667rem;
	}
}
h4 {
	font-size:1.333rem;
	line-height:1.1923;
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.444rem;
	}
}
h5 {
	font-size:1.111rem;
	line-height:1.1818;
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.222rem;
	}
}
h6 {
	font-size:1rem;
	line-height:1.2;
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.111rem;
	}
}

p {
	margin:0 0 $vert-space;
}
main,
.postscript-first {
	.card-body,
	.card-description {
		p, address, pre, ul, ol {
			font-outer-wrappersize: $base-font-size;
			line-height: $base-line-height;
			
			&.smaller-text {
				font-size: 0.889rem;
				line-height: 1.5;
				
				p, li {
					font-size: 0.889rem;
				}
			}
			&.larger-text {
				font-size: 1.222rem;  
				line-height: 1.4545;
				p, li {
					font-size: 1.222rem;
				}
			}				
			@include media($normal) {
				font-size:1rem;
				line-height: 1.2777   ;
				
				&.smaller-text {
					font-size: 0.889rem;
					line-height: 1.5;
					
					p, li {
						font-size: 0.889rem;
					}
				}
				&.larger-text {
					font-size: 1.222rem;  
					line-height: 1.4545;
					p, li {
						font-size: 1.222rem;
					}
				}					
			}
		}
		.caption {
			display: block;
			line-height: 1.5em;
			text-align: left;

			p {
				font-size: 0.75em;
				line-height: 1.5;

				@include media($normal) {
					font-size:0.889rem;
					line-height: 1.5;
				}
			}
		}
	}
}
b, strong {
	font-weight:700;
}

a, a:link, a:active, a:visited {
	color:$action-default;
	text-decoration:none;

	&.active {
		color:$action-active;
		text-decoration:none;
	}

	&:hover,
	h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
		color:$action-hover;
		text-decoration:none;
	}
	
	&:not([href]) {
		color: inherit;
	}
}

.date,
.card-date {
	font-size:0.750em;
	font-style:normal;
}

em, i {
	font-style: italic;
}

sup, sub {
	font-size: smaller;
	&:before {
		content: "";
		margin-left: 0.25em;
	}
}
sup { vertical-align: super; }
sub { vertical-align:sub; }

blockquote {
	position: relative;
	max-width: 60%;
  margin-left: auto;
  margin-right: auto;
	p {
		padding:0 10px 0 20px;
		font:300 1.555em/1 $title-font-family;
	}
	&:before,
	&:after {
		content:"";
		display:inline-block;
		height:30px;
		width:36px;
		position: absolute;
	}
	&:before {
		background:$open-quotes no-repeat 50% 50% / 36px 30px;
		left:-36px;
		top:0;
	}
	&:after {
		background:$close-quotes no-repeat 50% 50% / 36px 30px;
		right:-36px;
		bottom:0;
	}
}

.front {
	.card-body {
		p.larger-text {
			font-size: 1.556rem;
			line-height: 1.4285;
		}
   h2 {
	  text-align:center;
	  width:85%;
	  margin:0 auto;
    }
	}
}

main,
.postscript-first {
	.section-row {
		.view-news-events {
			.view-header {
				text-align: center;
				h2 {
					@include title-corner-arrows;
					display: inline-block;
					padding: 0 40px;
				}
			}
		}
	}
}
.postscript-first {
	.section-row {
		.view-news-events {
			.view-header {
				h2 {
					padding: 13px 40px;
				}
			}
		}
	}
}
