// /* CARDS */ //
// /* --- CARD DEFAULT SETTINGS */
$card-title-font-family		: $title-font-family;
$card-title-font        	: normal 1.111rem/1.2 $card-title-font-family;
$card-font-family       	: $base-font-family;
$card-more-link-font			: 500 1.222rem/1 $title-font-family;
$card-feed-link-font			: 700 1rem/1.2 $title-font-family;


$card-shadow                   : $box-shadow;
$card-border                   : none;
$card-border-radius            : 0px 0px 4px 4px;
$card-text-padding             : $vert-space $horz-space !default;
$card-margin-bottom            : $vert-space !default;
$card-transition             	 : background-color 300ms linear 0s;


// /* --- EVENT DISPLAY DATES
$card-date-bg-color            : rgba(60, 163, 217, 0.1);
$card-date-border              : none; //1px solid $pale_grey
$card-date-border-radius       : 4px 4px 0px 0px;
$card-date-text-color          : $secondary;
$card-date-font                : 0.778rem/1 $base-font-family;         


// /* --- HOVER STYLES --- */
$card-hover-bg-color           : $white;
$card-hover-border             : 2px solid $maximum_blue;
$card-hover-shadow             : $box-shadow;
$card-action-hover             : $action-hover; //colour of card title text when you hover over it
$card-text-hover-color         : $primary; //colour of regular card text when you hover over it
$card-date-hover-text-color    : $primary;
$card-date-hover-bg-color      : transparent; // allows display dates (e.g. events) to have different hover background from the rest of the card

$card-hover-bg-color-alt       : $glitter-alt;
$card-hover-border-alt         : 2px solid $glitter-alt;
$card-action-hover-alt         : $blue_sapphire;
$card-text-hover-color-alt     : $primary;
$card-date-hover-text-color-alt: $primary;
$card-date-hover-bg-color-alt  : transparent;

$card-hover-bg-color-alt-2       : $pale_aqua;
$card-hover-border-alt-2         : 2px solid $pale_aqua;
$card-action-hover-alt-2         : $blue_sapphire;
$card-text-hover-color-alt-2     : $primary;
$card-date-hover-text-color-alt-2: $primary;
$card-date-hover-bg-color-alt-2  : transparent;

/*
// --- CARD SUMMARIES ON HOVER
// ----- Card summary can be hidden with the card-hidden-summary mixin.
// ----- uncomment the appropriate line in base/_cards.scss as appropriate
// ----- uncomment the appropriate line in mixins/_mixins.scss to include the _card-hidden-summary.scss mixin functinality
// ----- currently, this applies to box links only, to extend, adjust the mixin in mixins/_card-hidden-summary.scss 
*/
$card-text-hover-bg                 : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-hover-bg    : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-title-color : white; // alternative background for summary text revealed on hover
$card-text-with-summary-text-color  : white; // alternative background for summary text revealed on hover


// /* --- CARD-LIGHT - light coloured background */
$card-light-bg-color           : white;
$card-light-border             : 2px solid white;
$card-light-title-color        : $secondary;
$card-light-action-default     : $secondary;
$card-light-action-hover       : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-light-text-color         : $primary;
$card-light-subtitle-color     : $primary;         

// /* --- CARD-MEDIUM - medium coloured background */
$card-medium-bg-color          : white;
$card-medium-border            : 2px solid white;
$card-medium-title-color       : $secondary;
$card-medium-action-default    : $secondary;
$card-medium-action-hover      : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-medium-text-color        : $primary;
$card-medium-subtitle-color    : $primary;

// /* --- CARD-DARK - dark coloured background */
$card-dark-bg-color            : $cornflour_blue;
$card-dark-border              : 2px solid $cornflour_blue;
$card-dark-title-color         : $secondary;
$card-dark-action-default      : $secondary;
$card-dark-action-hover        : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-dark-text-color          : $primary;
$card-dark-subtitle-color      : $primary;

// /* --- CARD-HIGHLIGHT - alternative coloured background to make cards "pop pop" */
$card-highlight-bg-color       : $cornflour_blue;
$card-highlight-border-color   : transparent;
$card-highlight-title-color    : $secondary;
$card-highlight-action-default : $secondary;
$card-highlight-action-hover   : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-highlight-text-color     : $slate_gray;
$card-highlight-subtitle-color : $slate_gray;


// /* --- CARD-MORE-LINKS */
$card-more-link-default-bg          : white;
$card-more-link-default-color       : $primary;
$card-more-link-default-border      : 2px solid white;
$card-more-link-default-icon        : $arrow-dark-right no-repeat 100% 100% / 20px auto;
$card-more-link-default-hover-bg    : white;
$card-more-link-default-hover-border: 2px solid $maximum_blue;
$card-more-link-default-hover-color : $action-hover;
$card-more-link-default-hover-icon  : $arrow-maximum-right no-repeat 100% 100% / 20px auto;
 
$card-more-link-alt-bg              : $cornflour_blue;
$card-more-link-alt-color           : $secondary;
$card-more-link-alt-icon            : $arrow-dark-right no-repeat 100% 100% / 20px auto;
$card-more-link-alt-hover-bg        : $pale_aqua;
$card-more-link-alt-hover-color     : $blue_sapphire;
$card-more-link-alt-hover-icon      : $arrow-sapphire-right no-repeat 100% 100% / 20px auto;



// /* --- CARD-FEED-LINKS */
$card-feed-link-default-bg          : transparent; // background for the entire link
$card-feed-link-default-color       : $action-hover;
$card-feed-link-default-icon        : $stroke-maximum no-repeat 50% 50% / 109px 26px; //background for the default :after component
$card-feed-link-default-hover-bg    : none;
$card-feed-link-default-hover-color : $action-default;
$card-feed-link-default-hover-icon  : $stroke-sapphire no-repeat 50% 50% / 109px 26px;;

$card-feed-link-alt-bg              : none; // background for the entire link
$card-feed-link-alt-color           : $white;
$card-feed-link-alt-icon            : none; //background for the alternative :after component
$card-feed-link-alt-hover-bg        : none;
$card-feed-link-alt-hover-color     : $action-hover;
$card-feed-link-alt-hover-icon      : none;


// /* --- BUTTON LINKS   */
$button-link-default-bg                 : $glitter;
$button-link-default-border-color       : none;
$button-link-default-color              : $action-hover;
$button-link-default-icon               : none;
$button-link-default-hover-bg           : $azureish_white;
$button-link-default-hover-border-color : none;
$button-link-default-hover-color        : $action-default;
$button-link-default-hover-icon         : none;

$button-link-alt-bg                     : rgba(255, 255, 255, 0.2);
$button-link-alt-border-color           : none;
$button-link-alt-color                  : $action-hover;
$button-link-alt-icon                   : none;
$button-link-alt-hover-bg               : rgba(255, 255, 255, 0.2);
$button-link-alt-hover-border-color     : none;
$button-link-alt-hover-color            : white;
$button-link-alt-hover-icon             : none;