// /* FORMS  */ //
$fieldset-border-color : $secondary;
$form-box-shadow       : none;
$form-box-shadow-focus : none; 
$form-border-color     : $glitter;
$form-border           : 1px solid $form-border-color;
$form-border-radius    : 20px;
$form-select-bg				 : $ghost_white;
$form-select-font			 : normal 0.777em / 1 $base-font-family;

$search_bg						 : $azureish_white;
$search_bg_hover       : $glitter;
$reset_bg	             : $glitter;
$reset_bg_hover        : $azureish_white;

fieldset {
	border: $secondary-border;
	margin: 0 0 $vert-space/2;
	padding: $vert-space $horz-space;
}

input,
label,
select {
	display: block;
	font-family: $base-font-family;
	font-size: $base-font-size;
}

label {
	margin-bottom: $vert-space/4;

	&.required::after {
		content: "*";
	}

	abbr {
		display: none;
	}
}

#{$all-text-inputs},
select[multiple=multiple],
textarea {
	background-color: $form-select-bg;
	border: $form-border;
	border-radius: $form-border-radius;
	box-shadow: $form-box-shadow;
	box-sizing: border-box;
	font:$form-select-font;
	margin-bottom: $vert-space/2;
	padding:3px 10px 5px;
	transition: border-color;
	width: 100%;
	height:30px;

	&:hover {
		border-color: darken($form-border-color, 2%);
	}
	
	&:focus {
		border-color: $action-default;
		box-shadow: $form-box-shadow-focus;
		outline: none;
	}
}

textarea {
	resize: vertical;
}

input[type="search"] {
	@include appearance(none);
}

input[type="checkbox"],
input[type="radio"] {
	display: inline;
	margin-right: $vert-space/4;
}

input[type="file"] {
	padding-bottom: $vert-space/2;
	width: 100%;
}

select {
	background:$arrow-filter no-repeat;
	background-position: 100% 50%;
	background-color: $form-select-bg;
	width: auto;
	border: $form-border;
	border-radius: $form-border-radius;
	box-shadow: $form-box-shadow;
	box-sizing: border-box;
	font:$form-select-font;
	margin-bottom: $vert-space;
	max-width: 100%;
	padding: 3px 10px 5px;
	transition: border-color;
	height:30px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.views-exposed-form {
	background-color:white;
	box-shadow:0px 2px 8px rgba(0, 0, 0, 0.1);
	padding:30px;
	margin-bottom:30px;
	label {
		font: 300 1.111em/1 $title-font-family;
		margin-bottom: 20px;
	}
	.views-exposed-widget {
		&.views-reset-button {
			.form-submit { 
				background-color:$reset_bg;
				color:$outer-space;
				@include button-corner-space();
				&:hover {
					background-color:$reset_bg_hover;
					color:$outer-space;
					@include button-corner-slate();
				}
			}
		}
		&.views-submit-button {
			.form-submit {
				background-color:$search_bg;
				color:$blue_sapphire;
				@include button-corner-sapphire();
				&:hover {
					background-color:$search_bg_hover;
					color:$maximum_blue;
					@include button-corner-maximum();
				}
			}
		}
	}
}
// Highlight palette forms
.postscript-first .section-row {
	&.palette-highlight {
		.block-webform {
			input[type="submit"] {
				@include button-corner-maximum;
				background-color:$button-link-alt-bg;
				color: $button-link-alt-color;
				&:hover {
					@include button-corner-white;
					background-color:$button-link-alt-hover-bg;
					color: $button-link-alt-hover-color;
				}
			}
		}
	}
}