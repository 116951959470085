// /* SLICK CONTROLS PALETTE */
$slick-default-nav-bg              				: $blue_sapphire; // used on slide and galler views
$slick-default-controls-bg         				: none; // used on twitter views etc
$slick-default-prev-bg             				: transparent $arrow-blue-left no-repeat scroll 50% 50%;
$slick-default-prev-hover-bg      				: transparent $arrow-sapphire-left no-repeat scroll 50% 50%;
$slick-default-next-bg            				: transparent $arrow-blue-right no-repeat scroll 50% 50%;
$slick-default-next-hover-bg      				: transparent $arrow-sapphire-right no-repeat scroll 50% 50%;
$slick-default-dot-border         				: 1px solid $primary;
$slick-default-dot-hover-border    				: 1px solid $blue_sapphire;
$slick-default-dot-active-border    			: 1px solid $maximum_blue;
$slick-default-dot-hover-bg-color    			: $blue_sapphire;
$slick-default-dot-active-bg-color 				: $maximum_blue;
$slick-default-dot-active-hover-bg-color 	: $blue_sapphire;


$slick-alt-1-nav-bg                				: $blue_sapphire; // used on slide and galler views
$slick-alt-1-controls-bg           				: none; // used on twitter views etc
$slick-alt-1-prev-bg               				: transparent $arrow-blue-left no-repeat scroll 50% 50%;
$slick-alt-1-prev-hover-bg         				: transparent $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-1-next-bg               				: transparent $arrow-blue-right no-repeat scroll 50% 50%;
$slick-alt-1-next-hover-bg         				: transparent $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-1-dot-border    				        : 1px solid white;
$slick-alt-1-dot-hover-border      				: 1px solid $blue_sapphire;
$slick-alt-1-dot-active-border            : 1px solid $maximum_blue;
$slick-alt-1-dot-hover-bg-color    			  : $blue_sapphire;
$slick-alt-1-dot-active-bg-color   				: $maximum_blue;
$slick-alt-1-dot-active-hover-bg-color 		: $blue_sapphire;


$slick-alt-2-nav-bg                 			: $blue_sapphire; // used on slide and galler views
$slick-alt-2-controls-bg         					: none; // used on twitter views etc
$slick-alt-2-prev-bg             			  	: transparent $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-2-prev-hover-bg      			   	: transparent $arrow-blue-left no-repeat scroll 50% 50%;
$slick-alt-2-next-bg            			   	: transparent $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-2-next-hover-bg      			   	: transparent $arrow-blue-right no-repeat scroll 50% 50%;
$slick-alt-2-dot-border         			   	: 1px solid white;
$slick-alt-2-dot-hover-border    			   	: 1px solid white;
$slick-alt-2-dot-active-border    		   	: 1px solid $maximum_blue;
$slick-alt-2-dot-hover-bg-color    		   	: white;
$slick-alt-2-dot-active-bg-color 			   	: $maximum_blue;
$slick-alt-2-dot-active-hover-bg-color   	: white       ;

$slick-alt-3-nav-bg                 			: $maximum_blue; // used on slide and galler views
$slick-alt-3-controls-bg         					: none; // used on twitter views etc
$slick-alt-3-prev-bg             			  	: transparent $arrow-gunmetal-left no-repeat scroll 50% 50%;
$slick-alt-3-prev-hover-bg      			   	: transparent $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-3-next-bg            			   	: transparent $arrow-gunmetal-right no-repeat scroll 50% 50%;
$slick-alt-3-next-hover-bg      			   	: transparent $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-3-dot-border         			   	: 1px solid $secondary;
$slick-alt-3-dot-hover-border    			   	: 1px solid white;
$slick-alt-3-dot-active-border    		   	: 1px solid $secondary;
$slick-alt-3-dot-hover-bg-color    		   	: white;
$slick-alt-3-dot-active-bg-color 			   	: $secondary;
$slick-alt-3-dot-active-hover-bg-color   	: white;


// /* SLIDES - card-slide-description */ //
$slide-text-bg-color       : $warm_black;
$slide-text-color          : white;
$slide-text-font           : 300 1.111rem/1.4545 $title-font-family;
$slide-text-border-radius  : 6px 6px 0px 0px;