.item-list  ul.pager {
	position: relative;
	padding: 10px 0;
	@media screen and (max-width: $narrow) {
		font-size: 0.750em;
	}
	a {
		height: 46px;
		width: 46px;
	}
	li.pager-next {
		float: right;
		position: absolute;
		right: 55px;
		top: 0;
		@media screen and (max-width: $narrow) {
			right: 35px;
		}
		a {
			display: block;
			background: $arrow-blue-right no-repeat 50% 50% / 20px 20px;
			text-indent: -9999px;
			overflow: hidden;
			&:before {
				content: "";
				display: block;
				height: 46px;
				width: 46px;
				background: $slick-button-bg;
				
			}
			&:hover {
				background: $arrow-sapphire-right no-repeat 50% 50% / 20px 20px;
			}
		}
	}
	li.pager-last {
		float: right;
		a {
			display: block;
			background: $arrow-blue-right no-repeat 50% 50% / 20px 20px;
			text-indent: -9999px;
			overflow: hidden;
			&:before {
				content: "";
				display: block;
				height: 46px;
				width: 46px;
				background: $slick-button-bg;
				
			}
			&:hover {
				background: $arrow-sapphire-right no-repeat 50% 50% / 20px 20px;
			}
		}
	}
	li.pager-previous {
		float: left;
		position: absolute;
		left: 55px;
		top: 0;
		@media screen and (max-width: $narrow) {
			left: 35px;
		}
		a {
			display: block;
			background: $arrow-blue-left no-repeat 50% 50% / 20px 20px;
			text-indent: -9999px;
			overflow: hidden;
			&:before {
				content: "";
				display: block;
				height: 46px;
				width: 46px;
				background: $slick-button-bg;
			}
			&:hover {
				background: $arrow-sapphire-left no-repeat 50% 50% / 20px 20px;
			}
		}
	}
	li.pager-first {
		float: left;
		a {
			display: block;
			background: $arrow-blue-left no-repeat 50% 50% / 20px 20px;
			text-indent: -9999px;
			overflow: hidden;
			&:before {
				content: "";
				display: block;
				height: 46px;
				width: 46px;
				background: $slick-button-bg;
			}
			&:hover {
				background: $arrow-sapphire-left no-repeat 50% 50% / 20px 20px;
			}
		}
	}
	li,
	li.pager-item {
		display: inline-block;
		margin: 0 3px;
	}
	li.pager-current {
		// background: $action-default;
		color:$primary;
		padding:0.25em 0.5em;
		margin-top: 0.25em;
		//border-radius:50%;
		//border-top: 3px solid;
		//border-bottom: 3px solid;
	}
}