// /* FOOTER */ //
$footer-first-bg           : $secondary;
$footer-first-text         : white;
$footer-first-action       : $action-default;
$footer-first-action-hover : $action-hover;
$footer-first-border       : none;

//
// --- Set the card style for FOOTER-FIRST by replacing the card-[mixin] in the mixin below
//
@mixin palette-footer-first-cards {
	@include card-light();
}
@mixin palette-footer-first-card-more-link {
	@include card-more-link-default();
}
@mixin palette-footer-first-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-footer-first-slick-controls {
	@include slick-controls-alt-2();
}


$footer-second-bg           : $secondary;
$footer-second-text         : white;
$footer-second-action       : $action-default;
$footer-second-action-hover : $action-hover;

//
// --- Set the card style for FOOTER-SECOND by replacing the card-[mixin] in the mixin below
//
@mixin palette-footer-second-cards {
	@include card-light();
}
@mixin palette-footer-second-card-more-link {
	@include card-more-link-default();
}
@mixin palette-footer-second-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-footer-second-slick-controls {
	@include slick-controls-alt-2();
}

// /* FOOTER CARD-LINKS  -- Follow . social media icons */
$card-links-action-bg            : rgba(255, 255, 255, 0.1);
$card-links-border-radius        : 50%;
$card-links-width                : 40px;
$card-links-height               : 40px;
$card-links-hover-opacity        : 1;
$card-links-hover-border-radius  : 50%;
$card-links-hover-bg             : rgba(255, 255, 255, 0.1);


$footer-third-bg            : white;
$footer-third-text          : $slate_gray;
$footer-third-action        : $action-default;
$footer-third-action-hover  : $action-hover;