@import url(https://use.typekit.net/dov4ehg.css);

$base-font-family      		: 'source-sans-pro', sans-serif;
$title-font-family    		: 'museo-slab', serif;
$subtitle-font-family 	 	: 'museo-slab',serif;

$base-font-size						: 18px;
$base-line-height					: 1.2777;
     
$base-font-size-normal		: 18px;

$button-link-font 				: 700 1.111rem/1.2 $title-font-family;

/* FORMS */
$button-font 							: 700 1.111rem/1.2 $title-font-family;