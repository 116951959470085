.credit,
.vid-description {
	background: white $ico-info-close 100% 50% no-repeat;
	border: none;
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	float: right;
	font-size: 1rem;
	lin-height: normal;
	margin-bottom: -27px;
	margin: 0;
	padding: 8px 60px 8px 20px;
	border-radius: 40px;
	position: absolute;
	text-align: right;
	top: 30px;
	right: 40px;
	
	&:empty {
		display: none;
	}

	&.collapsed {
		background: $ico-info 50% 50% no-repeat, $ico-bg 50% 50% no-repeat;
		background-color: rgba(42, 45, 52, 0.6);
		height: 40px;
		margin-right: 0;
		text-align: inherit;
		text-indent: -9999px;
		transition: background-color 0.250s linear;
		width: 40px;
		border-radius: 50%;
		padding: 0;

		&:hover {
			background: $ico-info-hover 50% 50% no-repeat, $ico-bg-hover 50% 50% no-repeat;
			background-color: $azureish_white;
		}
	}
	.card-video-upload-1 {
		padding: 12px;
	}
}

.vid-description {
	display: none;
	@include media(480px) {
		display: block;
	}
}

.postscript-first,
.content-hero {
	.section-row.palette-highlight {
		.credit {
			color: $primary;
		}
	}
}