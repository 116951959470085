// /* DEFAULT CARD LAYOUT	*/
.card {
	border:$card-border;
	border-radius: $card-border-radius;
	margin-bottom:$card-margin-bottom;
	display:flex;
	flex-flow:column;
	
	//transition: $card-transition;

	&.slick-slide {
		margin-top: 10px;
	} 
	.card-title,
	.card-title-et,
	.card-title-field {
		font-family:$card-title-font-family;
	}

	h3 {
		display:block;
		font:$card-title-font;
		
		.card-title-field,
		.card-title-et,
		.card-title {
			display:block;
			font:$card-title-font;
		}
	}	
	.card-text {
		padding: $card-text-padding;
		box-shadow:$card-shadow;
		height:100%;
		max-height:100%;
		a { word-wrap: break-word; }
	}
}

.card-display-date {
	background: $card-date-bg-color;
	border-bottom:$card-date-border;
	border-radius:$card-date-border-radius;
	color:$card-date-text-color;
	font:$card-date-font;
	padding:0 0 12px; //12px 20px 10px;
}


// /* MORE LINK */
.section-row:not(.view-type-slider) //, .side-row:not(.view-type-slider) /* to target sidebar more links separately */ 
	{
	.card-more-link {
		display:table !important;
		margin-left:2.35765%;
		text-align:center;
		text-transform: uppercase;

		@media (min-width:0) and (max-width:540px) {
			margin-left:0;
			width:100%;
		}

		a.card {
			box-sizing:border-box;
			display:table-cell;
			font:$card-more-link-font;
			padding:$vert-space $horz-space;
			vertical-align:middle;
			width:100%;

			@media (min-width:0) and (max-width:719px) {
				display: block;
			}

			&:after {
				content:" ";
				display:inline-block;
				height:16px;
				margin:0 0 -2px 10px;
				width:20px;
			}
		}
	}

	&.row-max-1 {
		.card-more-link,
		.card-last-link-url {
			margin-left: 0;
			float: right;
		}
	}
}

.side-row .card-more-link a.card {
	padding:$vert-space $horz-space;
	text-align: center;
	text-transform: uppercase;
	font:$card-more-link-font;
	&:after {
		content:" ";
		display: inline-block;
		height: 20px;
		margin:0 0 -4px 10px;
		width: 20px;
	}
}

// /* FEED LINK */
.card-feed-link {
	clear: both;
	display: block;
	float: right;
	font:$card-feed-link-font;
	overflow: visible;
	a {
		position: relative;
		background-size:109px 26px;
		&:after {
			content: "";
			display: inline-block;
			height:26px;
			width:109px;
			//margin-left: 10px;
			//vertical-align: sub;
			position: absolute;
    	left: -30px;
    	top: -5px;
		}
	}
}



// /* INNER CARD ELEMENTS */
.tweet .card-text {
	display:block;
	font:normal 1.125em/1.22222222222222 $base-font-family;
}

.card-logo {
	background:#FFFFFF;
}
.card-video {
	margin-bottom:15px;
	
	span.ext {
		display:none;
	}
}

.card-tags {
	display: inline-block;
	font-size: 0.875em;
	font-weight: 600;

	ul {
		display: inline-block;
		li {
			float: left;
			margin-right: 10px;
			&:after {
				content:"|";
				display: inline-block;
				float: right;
				margin-left: 10px;
			}
			&:last-child {
				&:after {
					content:"";
				}
			}					
		}
	}
}

.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}

.card-citation {
	font-style:italic;
}
.card-date,
.card-location {
	font-size:0.875em;
	line-height:1.57142857142857;
	color:$slate_gray;
}
.card-location {
	margin-top: 16px;
}
.card-type {
	font-size:0.777rem;
	line-height:1.57142857142857;
}
.card-label {
	font-weight:bold;
}
.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}

.card-date-display-override {
	font-size: 0.875em;
	margin-bottom: 20px;
}
.card-resource-type,
.card-content-type {
	display:inline-block;
	font-weight: 400;
	font-size: 0.875em;
}
.card-email {
	font-weight: 600;
}

.card-job-title {
	font-size:1em;
	line-height:1.375;
}

.card-footer {
	display: block;
	line-height: 1.125em;
}

@media (min-width:$narrow) and (max-width:959px) {
	.card-with-summary {
		.card-summary {
			font-size: 0.875rem;
			line-height: 1.42857142857143;
		}
	}
}
//Display box-link card-summary on hover 
//@include card-hidden-summary;

// FUCKING EVENT DATES :(

.node-event a.card {
	overflow: visible;
	margin-top:57px;
	&.card-with-image {
		overflow: hidden;
	}
	&:hover .card-text .event-date-wrapper {
		background:$date_bg_default_hover;
	}
	.card-text {
		position: relative;
		overflow: visible;
		.event-date-wrapper {
			position: absolute;
			background:$date_bg_default;
			font-family:$title-font-family;
			color:$primary;
			top: -57px;
	    left: -2px;
	    border-radius: 5px 5px 0 0;
	    padding: 5px 20px;
			.date-d {
				font-size: 36px;
	    	float: left;
	    	margin-right: 10px;
			}
			.date-my {
				float: right;
	    	font-size: 14px;
	    	margin-top: 7px;
			}
		}
	}
}

.section-row {
	&.palette-default {
		.node-event a.card {
			.card-text .event-date-wrapper {
				background:$date_bg_default;
			}
			&:hover .card-text .event-date-wrapper {
				background:$date_bg_default_hover;
			}
		}
	}
	&.palette-alt-1 {
		.node-event a.card {
			.card-text .event-date-wrapper {
				background:$date_bg_alt_1;
			}
			&:hover .card-text .event-date-wrapper {
				background:$date_bg_alt_1_hover;
			}
		}
	}
	&.palette-alt-2 {
		.node-event a.card {
			.card-text .event-date-wrapper {
				background:$date_bg_alt_2;
			}
			&:hover .card-text .event-date-wrapper {
				background:$date_bg_alt_2_hover;
			}
		}
	}
	&.palette-alt-3 {
		.node-event a.card {
			.card-text .event-date-wrapper {
				background:$date_bg_alt_3;
			}
			&:hover .card-text .event-date-wrapper {
				background:$date_bg_alt_3;
			}
		}
	}
	&.palette-highlight {
		.node-event a.card {
			.card-text .event-date-wrapper {
				background:$date_bg_highlight;
			}
			&:hover .card-text .event-date-wrapper {
				background:$date_bg_highlight_hover;
			}
		}
	}
}

.side-row .node-event a.card {
	margin-top:77px;
	&.card-with-image {
		margin-top:0;
		.card-image {
    	float: none;
    	width: 100%;
		}
	}
}
.view-id-links {
	.node-event a.card {
		&.card-with-image {
			margin-top: 0;
		}
	}
}

// Tagged/related views
.view-tagged-publications,
.view-tagged,
.view-related {
	.card-title-et,
	.card-related {
		h3,
		h4 {
			font-size: 1.111rem;
			line-height: 1.2;
		}
	}
}
	
.row-max-2,
.row-max-1 {
  .card {
    @media(min-width:720px) {
      flex-flow: row;
      .card-image {
        min-width:48%;
      }
    }
  }
}
.side-row {
	&.row-max-2,
	&.row-max-1 {
	  .view-organisations .card {
	  	flex-flow: column;
	    .card-logo {
	    	min-width:100%; 
	    }
	  }
	}
}
