// /* HEADER */ //

$header-menu-font 				: 0.778rem/1.2857 $base-font-family;
$header-search-field-font : 0.778rem/1.2857 $base-font-family;

$header-bg-color                   : none;

$header-menu-link-color            : $primary;
$header-menu-link-hover            : $action-hover;

$header-search-field-bg-color      : $ghost_white;
$header-search-field-text-color    : $secondary;
$header-search-field-box-shadow    : none;
$header-search-field-border        : 1px solid $azureish_white;
$header-search-field-border-radius : 20px;